<template>
    <layout-full-width :title="$tc('library', 2)">
        <component-actions :action-filters="[]"
                           :bulk="bulk"
                           :bulk-actions="[]"
                           :extended-search="[]"
                           :general-search-fields="[]"
                           :items-per-page="itemsPerPage"
                           :page="page"
                           :primary-actions="primaryActions"
                           :secondary-actions="[]"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listMediaLibraries"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.MEDIA_LIBRARY_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortMediaLibrary"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.MEDIA_LIBRARY_LIST && mediaLibraries.list.length > 0">
                            <tr v-for="(mediaLibrary, i) in mediaLibraries.list"
                                :key="'media-library-' + i"
                                class="pointer"
                                @click="onClickMediaLibrary($event, mediaLibrary)">
                                <td>
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="mediaLibrary.selected ? 'text-primary' : ''"
                                            :icon="mediaLibrary.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td>{{ mediaLibrary.mediaLibraryDescShort }}</td>
                                <td>{{ mediaLibrary.mediaLibraryDescLong }}</td>
                                <td>
                                    <template v-if="mediaLibrary.meta_update_dt">
                                        {{ mediaLibrary.meta_update_dt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ mediaLibrary.meta_insert_dt | timestamp }}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.MEDIA_LIBRARY_LIST && mediaLibraries.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.library.no-libraries-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.MEDIA_LIBRARY_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <component-update-media-library ref="viewMediaLibrary"
                                        :active="update.active"
                                        @delete="onClickDeleteMediaObject"
                                        @close="update.active = false"/>

        <!-- Trash confirmation -->
        <component-trash-confirmation :object="this.mediaObject"
                                      :skip="true"
                                      :title="$t('confirmations.trash.mediaObject.title')"
                                      @trash="onDeleteMediaObject"/>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../layouts/LayoutFullWidth";
import ComponentActions from '../../components/ui/ComponentActions.vue';
import ComponentShimmer from "../../components/ComponentShimmer";
import ComponentUpdateMediaLibrary from "../../components/ComponentUpdateMediaLibrary";
import ComponentTrashConfirmation from "../../components/ui/ComponentTrashConfirmation";
import shouldOpenSlideIn from "../../lib/should-open-slide-in";
import ComponentTableHeader from '../../components/ui/ComponentTableHeader.vue';
import S3 from "../../lib/s3";

export default {
    name: "PageDatabases",
    components: {
        ComponentTrashConfirmation,
        ComponentShimmer,
        LayoutFullWidth,
        ComponentActions,
        ComponentUpdateMediaLibrary,
        ComponentTableHeader
    },
    computed: {
        loading() {
            return {
                MEDIA_LIBRARY_LIST: this.$store.state.loading.MEDIA_LIBRARY_LIST
            }
        },
        mediaLibraries() {
            return this.$store.state.MEDIA_LIBRARY.mediaLibraries
        },
        partyUniqueId() {
            return this.$store.state.USER.user.partyUniqueId;
        }
    },
    data() {
        return {
            mediaObject: {},
            filter: {},
            order: {meta_insert_dt: "DESC"},
            page: 1,
            itemsPerPage: 10,
            total: 0,
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    disabled: true,
                    target: ''
                }
            ],
            bulk: false,
            update: {
                active: false
            },
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_media_library_desc_short'
                },
                {
                    title: this.$tc('description', 1),
                    sortField: 'gen_media_library_desc_long'
                },
                {
                    title: this.$t('modified')
                }
            ]
        }
    },
    methods: {
        listMediaLibraries() {
            this.$store.dispatch('MEDIA_LIBRARY/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(mediaLibraries => {
                    this.itemsPerPage = mediaLibraries.itemsPerPage;
                    this.page = mediaLibraries.page;
                    this.total = mediaLibraries.total;
                });
        },
        onSortMediaLibrary(order) {
            this.order = order;
            this.listMediaLibraries();
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listMediaLibraries();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listMediaLibraries();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listMediaLibraries();
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.mediaLibraries.list.forEach(mediaLibrary => mediaLibrary.selected = false);
                } else {
                    this.mediaLibraries.list.forEach(mediaLibrary => mediaLibrary.selected = true);
                }
            } else {
                this.mediaLibraries.list[i].selected = !this.mediaLibraries.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const count = this.mediaLibraries.list.length;
            let selected = 0;

            this.mediaLibraries.list.forEach(mediaLibrary => {
                if (mediaLibrary.selected) {
                    selected += 1;
                }
            });

            return count === selected && count !== 0;
        },
        onClickMediaLibrary(event, mediaLibrary) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update.active = true;
            this.$store.dispatch('MEDIA_LIBRARY/GET', mediaLibrary);
        },
        onClickDeleteMediaObject(mediaObject) {
            this.mediaObject = mediaObject;
        },
        onDeleteMediaObject(mediaObject) {
            this.$store.dispatch('MEDIA_OBJECT/DELETE', mediaObject.mediaObjectUniqueId)
                .then(() => {
                    S3.deleteObject({
                        Bucket: this.getBucket(mediaObject),
                        Key: mediaObject.mediaObjectUniqueId
                    }, (error) => {
                        if (error) {
                            this.$store.dispatch('toast/success', {
                                title: this.$tc('toast.danger.error'),
                                message: error.message,
                            });
                        } else {
                            this.$refs.viewMediaLibrary.listMediaObjects();
                            this.$store.dispatch('toast/success', {
                                title: this.$t('toast.success.success'),
                                message: this.$t('toast.success.media-object-deleted'),
                            });
                        }
                    })
                })
                .catch(error => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message,
                    });
                });
        },
        getBucket(mediaObject) {
            let Body = process.env.VUE_APP_AWS_BUCKET_NAME + '/' + this.partyUniqueId;

            switch (mediaObject.mediaObjectType) {
                case 'MEDIA_OBJECT_IMPORT_RESULT':
                    return Body + '/p00057/LOG/PROCESS_JOB/' + mediaObject.mediaProperties.fullPath.split('/')[7];
                case 'MEDIA_OBJECT_IMPORT':
                    return Body + '/MEDIA_LIBRARY';
            }
        },
    },
    beforeMount() {
        this.listMediaLibraries();
    }
}
</script>

<style lang="scss" scoped>
</style>
