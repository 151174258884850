<template>
    <component-slide-in :class="{ active }" @close="close" :size="size">

        <template v-if="!loading.MEDIA_LIBRARY_GET && Object.keys(mediaLibrary).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ mediaLibrary.mediaLibraryDescShort.substr(0, 1).toUpperCase() }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :title="mediaLibrary.mediaLibraryDescShort"
                                          :uuid="mediaLibrary.mediaLibraryUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'general'}"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'general'; size = ''"
                        role="tab"
                        type="button">
                    {{ $t('general') }}
                </button>
                <button id="nav-media-object-tab"
                        aria-controls="nav-media-object"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'mediaObject'}"
                        data-bs-target="#nav-media-object"
                        data-bs-toggle="tab"
                        @click.prevent="listMediaObjects(); tab = 'mediaObject'; size = 'lg'"
                        role="tab"
                        type="button">
                    {{ $tc('media-object', 2) }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'references'}"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'references'; size = ''"
                        role="tab"
                        type="button">
                    {{ $tc('reference', 2) }}
                </button>
                <button id="nav-comments-tab"
                        aria-controls="nav-comments"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'comments'}"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'comments'; size = ''"
                        role="tab"
                        type="button">
                    {{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'activity'}"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'activity'; size = ''"
                        role="tab"
                        type="button">
                    {{ $tc('activity', 1) }}
                </button>
            </nav>

        </template>

        <template v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                    <div>
                        <component-shimmer class="mb-2" height="16.8px" width="50px"/>
                        <component-shimmer class="mb-1" height="26.8px" width="100%"/>
                    </div>
                </div>
            </div>
        </template>

        <div id="nav-tabContent" class="tab-content">
            <div id="nav-general"
                 aria-labelledby="nav-general-tab"
                 class="tab-pane fade"
                 :class="{'active show': tab === 'general'}"
                 role="tabpanel">
                <form @submit.prevent="onUpdateMediaLibrary">
                    <div class="row">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="media-object-desc-short">{{ $t('short-name') }}</label>
                                    <input id="media-object-desc-short"
                                           v-model="descShort"
                                           :aria-label="$t('short-name')"
                                           :class="{'is-invalid': this.$v.descShort.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="media-object-desc-short"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descShort.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.desc-short') }}
                                    </div>
                                    <div v-if="!this.$v.descShort.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-short', {count: $v.descShort.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="media-object-desc-long">{{ $tc('name', 1) }}</label>
                                    <input id="media-object-desc-long"
                                           v-model="descLong"
                                           :aria-label="$tc('name', 1)"
                                           :class="{'is-invalid': this.$v.descLong.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="media-object-desc-long"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descLong.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-long', {count: $v.descLong.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="media-object-comment">{{ $tc('description', 1) }}</label>
                                    <textarea id="media-object-comment"
                                              v-model="comment"
                                              :aria-label="$tc('comment', 1)"
                                              :class="{'is-invalid': this.$v.comment.$invalid}"
                                              :disabled="!edit.flag"
                                              aria-describedby="media-object-comment"
                                              class="form-control form-control-sm"
                                              rows="6"
                                              type="text"></textarea>
                                    <div v-if="!this.$v.comment.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.comment', {count: $v.comment.$params.maxLength.max}) }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            disabled="disabled"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div id="nav-media-object"
                 aria-labelledby="nav-media-object-tab"
                 class="tab-pane fade"
                 :class="{'active show': tab === 'mediaObject'}"
                 role="tabpanel">
                <component-actions :action-filters="mediaObjectsList.actionFilters"
                                   :bulk="mediaObjectsList.bulk"
                                   :bulk-actions="[]"
                                   :extended-search="[]"
                                   :general-search-fields="mediaObjectsList.generalSearchFields"
                                   :items-per-page="mediaObjectsList.itemsPerPage"
                                   :page="mediaObjectsList.page"
                                   :primary-actions="[]"
                                   :secondary-actions="[]"
                                   :total="mediaObjectsList.total"
                                   @applyFilters="onApplyFilters"
                                   @changeItemsPerPage="onChangeItemsPerPage"
                                   @paginate="onChangePage"
                                   @refresh="listMediaObjects"/>
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table :class="{'table-hover': !loading.MEDIA_OBJECT_LIST}" class="table">
                                <thead>
                                <component-table-header :heading-columns="mediaObjectsList.headingColumns"
                                                        :select-all-status="getSelectAllStatus()"
                                                        :show-checkbox="true"
                                                        @sort="onSortMediaObject"
                                                        @toggleCheckbox="toggleCheckbox"/>
                                </thead>
                                <tbody>
                                <template v-cloak v-if="!loading.MEDIA_OBJECT_LIST && mediaObjects.list.length > 0">
                                    <tr v-for="(mediaObject, i) in mediaObjects.list"
                                        @click="onClickMediaObject($event, mediaObject)"
                                        class="pointer"
                                        :key="'media-library-' + i">
                                        <td style="width: 2rem">
                                            <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                                <font-awesome-icon
                                                    :class="mediaObject.selected ? 'text-primary' : ''"
                                                    :icon="mediaObject.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                                    class="pe-none"/>
                                            </button>
                                        </td>
                                        <td style="width: 100%">
                                            <font-awesome-icon class="text-info me-1" icon="file-alt"/>
                                            {{ mediaObject.mediaObjectDescShort + '.' + mediaObject.mediaProperties.extension }}
                                        </td>
                                        <td>
                                            <span class="badge bg-light">
                                                {{ mediaObject.mediaProperties.sizeInBytes | bytes }}
                                            </span>
                                        </td>
                                        <td style="min-width: 10em">
                                            <span class="badge"
                                                  :class="getMediaObjectExtensionClass(mediaObject.mediaProperties.extension)">
                                                {{ mediaObject.mediaProperties.extension }}
                                            </span>
                                        </td>
                                        <td style="min-width: 6em">
                                            <span :class="getMediaObjectTypeClass(mediaObject.mediaObjectType)"
                                                  class="badge">
                                                {{ getMediaObjectTypeLabel(mediaObject.mediaObjectType) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span :class="getMediaObjectStatusClass(mediaObject.mediaObjectStatus)"
                                                  class="badge">
                                                {{ getMediaObjectStatusLabel(mediaObject.mediaObjectStatus) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span style="white-space: nowrap;" v-if="mediaObject.meta_update_dt">
                                                {{ mediaObject.meta_update_dt | timestamp }}
                                            </span>
                                            <span style="white-space: nowrap;" v-else>
                                                {{ mediaObject.meta_insert_dt | timestamp }}
                                            </span>
                                        </td>
                                        <td style="width: 2rem">
                                            <div class="dropdown">
                                                <button id="media-object-list-dropdown"
                                                        aria-expanded="false"
                                                        aria-haspopup="true"
                                                        class="btn p-0 bg-transparent"
                                                        data-bs-toggle="dropdown"
                                                        type="button">
                                                    <font-awesome-icon class="pe-none" icon="ellipsis-h"/>
                                                </button>
                                                <div aria-labelledby="media-object-list-dropdown"
                                                     class="dropdown-menu dropdown-menu-end">
                                                    <button class="dropdown-item"
                                                            type="button"
                                                            @click="onDownloadMediaObject(mediaObject)">
                                                        {{ $t('actions.download') }}
                                                    </button>
                                                    <button class="dropdown-item text-danger"
                                                            type="button"
                                                            data-bs-target="#trash-confirmation"
                                                            data-bs-toggle="modal"
                                                            @click="$emit('delete', mediaObject)">
                                                        {{ $t('actions.delete') }}
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-cloak v-if="!loading.MEDIA_OBJECT_LIST && mediaObjects.list.length === 0">
                                    <tr>
                                        <td colspan="99">
                                            <div class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                                 role="alert">
                                                <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                                <div class="mt-2 fs-4">{{ $t('pages.library.no-media-objects-found') }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="loading.MEDIA_OBJECT_LIST">
                                    <tr v-for="n in mediaObjectsList.itemsPerPage" :key="'row-shimmer-' + n">
                                        <td class="align-middle" style="width: 2rem">
                                            <component-shimmer width="1rem" height="1rem"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer height="1.2rem" width="100%"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer width="30px"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer width="10em"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer width="72px"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer width="42px"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer width="100px"/>
                                        </td>
                                        <td class="align-middle">
                                            <component-shimmer width="15px" height="9px"/>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id="nav-references"
                 aria-labelledby="nav-references-tab"
                 class="tab-pane fade"
                 :class="{'active show': tab === 'references'}"
                 role="tabpanel">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                        <tr>
                            <td class="border-bottom-0" colspan="99">
                                <div class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                     role="alert">
                                    <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                    <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="nav-comments"
                 aria-labelledby="nav-comments-tab"
                 class="tab-pane fade"
                 :class="{'active show': tab === 'comments'}"
                 role="tabpanel">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                        <tr>
                            <td class="border-bottom-0" colspan="99">
                                <div class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                     role="alert">
                                    <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                    <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="nav-activity"
                 aria-labelledby="nav-activity-tab"
                 class="tab-pane fade"
                 :class="{'active show': tab === 'activity'}"
                 role="tabpanel">
                <div class="list-group">
                    <div v-if="mediaLibrary.meta_update_dt" class="list-group-item">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <p class="mb-0">{{ $t('activity-logs.media-object-updated') }}</p>
                            <small>{{ mediaLibrary.meta_update_dt | timestamp }}</small>
                        </div>
                    </div>
                    <div class="list-group-item">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <p class="mb-0">{{ $t('activity-logs.media-object-created') }}</p>
                            <small>{{ mediaLibrary.meta_insert_dt | timestamp }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <component-update-media-object ref="viewMediaObject"
                                       :active="update.active"
                                       @close="update.active = false"
                                       @update="onUpdateMediaObject"/>

    </component-slide-in>

</template>

<script>
import ComponentSlideIn from "./ui/ComponentSlideIn";
import ComponentAvatar from "./ui/ComponentAvatar";
import ComponentSlideInTitle from "./ui/ComponentSlideInTitle";
import ComponentShimmer from "./ComponentShimmer";
import ComponentActions from "./ui/ComponentActions";
import S3 from "../lib/s3";
import {maxLength, required} from "vuelidate/lib/validators";
import ComponentTableHeader from "./ui/ComponentTableHeader";
import shouldOpenSlideIn from "../lib/should-open-slide-in";
import ComponentUpdateMediaObject from "./ComponentUpdateMediaObject";

export default {
    name: "ComponentUpdateMediaLibrary",
    components: {
        ComponentTableHeader,
        ComponentActions,
        ComponentSlideInTitle,
        ComponentAvatar,
        ComponentSlideIn,
        ComponentShimmer,
        ComponentUpdateMediaObject
    },
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                MEDIA_LIBRARY_GET: this.$store.state.loading.MEDIA_LIBRARY_GET,
                MEDIA_OBJECT_LIST: this.$store.state.loading.MEDIA_OBJECT_LIST
            }
        },
        mediaLibrary() {
            return this.$store.state.MEDIA_LIBRARY.mediaLibrary;
        },
        mediaObjects() {
            return this.$store.state.MEDIA_OBJECT.mediaObjects;
        },
        partyUniqueId() {
            return this.$store.state.USER.user.partyUniqueId;
        },
        descShort: {
            get() {
                return this.edit.flag ? this.edit.descShort : this.mediaLibrary.mediaLibraryDescShort;
            },
            set(mediaLibraryDescShort) {
                this.edit.descShort = mediaLibraryDescShort;
            }
        },
        descLong: {
            get() {
                return this.edit.flag ? this.edit.descLong : this.mediaLibrary.mediaLibraryDescLong;
            },
            set(mediaLibraryDescLong) {
                this.edit.descLong = mediaLibraryDescLong;
            }
        },
        comment: {
            get() {
                return this.edit.flag ? this.edit.comment : this.mediaLibrary.mediaLibraryComment;
            },
            set(mediaLibraryComment) {
                this.edit.comment = mediaLibraryComment;
            }
        }
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            size: '',
            tab: 'general',
            edit: {
                flag: false,
                descShort: '',
                descLong: '',
                comment: ''
            },
            update: {
                active: false
            },
            mediaObjectsList: {
                bulk: false,
                actionFilters: [
                    {
                        title: this.$tc('status', 1),
                        key: 'genStatus.gen_status_desc_short',
                        values: [
                            {
                                name: this.$t('statuses.mediaObject.MEDIA_OBJECT_ACTIVE'),
                                value: 'MEDIA_OBJECT_ACTIVE'
                            }
                        ]
                    },
                    {
                        title: this.$tc('type', 1),
                        key: 'genType.gen_type_desc_short',
                        values: [
                            {
                                name: this.$t('types.mediaObject.MEDIA_OBJECT_IMPORT'),
                                value: 'MEDIA_OBJECT_IMPORT'
                            },
                            {
                                name: this.$t('types.mediaObject.MEDIA_OBJECT_IMPORT_RESULT'),
                                value: 'MEDIA_OBJECT_IMPORT_RESULT'
                            }
                        ]
                    }
                ],
                generalSearchFields: ['gen_media_object_desc_short'],
                extendedSearch: [],
                headingColumns: [
                    {
                        title: this.$tc('name', 1),
                        sortField: 'gen_media_object_desc_short'
                    },
                    {
                        title: this.$tc('size', 1),
                    },
                    {
                        title: this.$tc('file-extension', 1)
                    },
                    {
                        title: this.$tc('type', 1),
                        sortField: 'genType.gen_type_desc_short'
                    },
                    {
                        title: this.$tc('status', 1),
                        sortField: 'genStatus.gen_status_desc_short'
                    },
                    {
                        title: this.$t('modified')
                    },
                    {
                        title: ''
                    }
                ],
                filters: {},
                order: {
                    meta_insert_dt: 'DESC'
                },
                itemsPerPage: 25,
                page: 1,
                total: 0,
            }
        }
    },
    methods: {
        listMediaObjects() {
            this.$store.dispatch('MEDIA_OBJECT/LIST', {
                filter: this.mediaObjectsList.filters,
                order: this.mediaObjectsList.order,
                page: this.mediaObjectsList.page,
                itemsPerPage: this.mediaObjectsList.itemsPerPage,
                limit: null
            })
                .then(mediaObjects => {
                    this.mediaObjectsList.itemsPerPage = mediaObjects.itemsPerPage;
                    this.mediaObjectsList.list = mediaObjects.list;
                    this.mediaObjectsList.page = mediaObjects.page;
                    this.mediaObjectsList.total = mediaObjects.total;
                });
        },
        onClickMediaObject(event, mediaObject) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.update.active = true;
            this.$store.dispatch('MEDIA_OBJECT/GET', mediaObject.mediaObjectUniqueId);
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.mediaObjectsList.page = parseInt(page, 10);
            this.listMediaObjects();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.mediaObjectsList.itemsPerPage === itemsPerPage) {
                return;
            }

            this.mediaObjectsList.page = 1;
            this.mediaObjectsList.itemsPerPage = itemsPerPage;
            this.listMediaObjects();
        },
        onApplyFilters(filters) {
            this.mediaObjectsList.filters = filters;
            this.listMediaObjects();
        },
        getMediaObjectTypeClass(mediaObjectType) {
            return {
                'bg-teal': mediaObjectType === 'MEDIA_OBJECT_IMPORT_RESULT',
                'bg-success': mediaObjectType === 'MEDIA_OBJECT_IMPORT',
            }
        },
        getMediaObjectTypeLabel(mediaObjectType) {
            if (this.$te('types.mediaObject.' + mediaObjectType, this.$i18n.locale)) {
                return this.$t('types.mediaObject.' + mediaObjectType)
            }

            return this.$t('unknown');
        },
        getMediaObjectExtensionClass(mediaObjectExtension) {
            return {
                'bg-indigo': mediaObjectExtension === 'csv',
                'bg-cyan': mediaObjectExtension === 'json',
            }
        },
        getMediaObjectStatusClass(mediaObjectStatus) {
            return {
                'bg-info': mediaObjectStatus === 'MEDIA_OBJECT_ACTIVE'
            }
        },
        getMediaObjectStatusLabel(mediaObjectStatus) {
            if (this.$te('statuses.mediaObject.' + mediaObjectStatus, this.$i18n.locale)) {
                return this.$t('statuses.mediaObject.' + mediaObjectStatus)
            }

            return this.$t('unknown');
        },
        getSelectAllStatus() {
            const mediaObjectCount = this.mediaObjects.list.length;
            let selectedMediaObjectCount = 0;

            this.mediaObjects.list.forEach(mediaObject => {
                if (mediaObject.selected) {
                    selectedMediaObjectCount += 1;
                }
            });

            return mediaObjectCount === selectedMediaObjectCount && mediaObjectCount !== 0;
        },
        onSortMediaObject(order) {
            this.mediaObjectsList.order = order;
            this.listMediaObjects();
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                const getSelectAllStatus = this.getSelectAllStatus();
                this.mediaObjects.list.forEach(mediaObject => mediaObject.selected = !getSelectAllStatus);
            } else {
                this.mediaObjects.list[i].selected = !this.mediaObjects.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getActionButton() {
            let selectedMediaObjectCount = 0;

            this.mediaObjects.list.forEach(mediaObject => {
                if (mediaObject.selected) {
                    selectedMediaObjectCount += 1;
                }
            })

            this.mediaObjectsList.bulk = selectedMediaObjectCount !== 0;
        },
        getBucket(mediaObject) {
            let Body = process.env.VUE_APP_AWS_BUCKET_NAME + '/' + this.partyUniqueId;

            switch (mediaObject.mediaObjectType) {
                case 'MEDIA_OBJECT_IMPORT_RESULT':
                    return Body + '/p00057/LOG/PROCESS_JOB/' + mediaObject.mediaProperties.fullPath.split('/')[7];
                case 'MEDIA_OBJECT_IMPORT':
                    return Body + '/MEDIA_LIBRARY';
            }
        },
        saveByteArray(reportName, byte, type) {
            const blob = new Blob([byte], {type});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
        },
        onDownloadMediaObject(mediaObject) {
            S3.getObject({
                Bucket: this.getBucket(mediaObject),
                Key: mediaObject.mediaObjectUniqueId
            }, (error, data) => {
                if (error) {
                    console.log(error);
                } else {
                    this.saveByteArray(
                        mediaObject.mediaObjectDescShort + '.' + mediaObject.mediaProperties.extension,
                        data.Body,
                        data.ContentLength,
                        data.ContentType
                    );
                }
            });
        },
        onUpdateMediaObject() {
            this.$store.dispatch('toast/success', {
                title: this.$t('toast.success.success'),
                message: this.$t('toast.success.media-object-updated'),
            });
        },
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onUpdateMediaLibrary() {
        },
        onClickEdit() {
            this.edit.descShort = this.mediaLibrary.mediaLibraryDescShort;
            this.edit.descLong = this.mediaLibrary.mediaLibraryDescLong;
            this.edit.comment = this.mediaLibrary.mediaLibraryComment;
            this.edit.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        },
    },
    validations: {
        descShort: {
            maxLength: maxLength(50),
            required
        },
        descLong: {
            maxLength: maxLength(255)
        },
        comment: {
            maxLength: maxLength(4000)
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    },
}
</script>

<style scoped>

</style>
>
