<template>
    <component-modal :id="id" ref="trashConfirmationModal" :title="title">
        <div class="modal-body">
            <div class="form-check mb-3">
                <input id="skip-trash-bin"
                       class="form-check-input"
                       disabled="disabled"
                       v-model="mutableSkip"
                       type="checkbox">
                <label class="form-check-label" for="skip-trash-bin">{{ $t('modal.trash-confirmation') }}</label>
            </div>
            <slot></slot>
            <p class="mb-0 text-right">
                <button class="btn btn-danger me-2" type="button" @click="onDelete">
                    <font-awesome-icon class="me-1" icon="trash"/>
                    {{ $t('actions.yes') }}
                </button>
                <button class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                    {{ $t('actions.no') }}
                </button>
            </p>
        </div>
    </component-modal>
</template>

<script>
import ComponentModal from "./ComponentModal";
import closeModal from "../../lib/close-modal";

export default {
    name: "ComponentTrashConfirmation",
    components: {ComponentModal},
    data() {
        return {
            mutableSkip: this.skip
        }
    },
    props: {
        object: {
            required: true
        },
        id: {
            type: String,
            default: 'trash-confirmation',
            required: false
        },
        title: {
            type: String,
            default: '',
            required: true
        },
        skip: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    methods: {
        onDelete() {
            this.$emit('trash', this.object);
            closeModal(this.$refs.trashConfirmationModal.$el);
        }
    }
}
</script>

<style lang="scss" scoped>
.btn {
    min-width: 6rem;
}
</style>
